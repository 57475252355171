* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body, #root, .App {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
}

header {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

content h1 {
  text-shadow: 0 0 4px darkblue;
  text-transform: uppercase;
  font-size: 72px;
}

content {
  background-image:
    linear-gradient(
      to bottom, 
      #7AD7F0, #83d0e4
    );
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  width: 100%;
}

select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
}

ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 356px;
  overflow-y: scroll;
}

li {
  background: white;
  color: black;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px dotted lightgray;
}

li:hover {
  background: #eeeeee;
}

button {
  background: #009ABB;
  text-shadow: 0 0 2px darkblue;
  color: white;
  font-size: 28px;
  width: 80%;
  height: 64px;
  padding: 16px;
  border: none;
  border-radius: 8px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  padding: 32px;
  width: 100%;
}

footer p {
  color: gray;
  width: 100%;
  max-width: 768px;
}

.sub-footer {
  background-color: white;
  padding: 4px 4px 0 4px;
  max-width: 768px;
  width: 100%;
}

.sub-footer ul {
  overflow-y: hidden;
}

.sub-footer li {
  font-size: 14px;
}

.sub-footer li a {
  text-decoration: none;
  color: black;
}

.sub-footer h3 {
  padding: 8px 0;
}

.sub-footer-outer {
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-wrapper {
  width: 30%;
  padding: 32px;
  border: 2px solid lightgray;
  background-color: #f5f5f5;
  border-radius: 16px;
  margin-bottom: 32px;
  margin-top: 32px;
}

@media screen and (max-width: 1280px) {
  .select-wrapper {
    width: 50%;
  }
  button {
    font-size: 20px;
  }
  html, body, #root, .App {
    height: auto;
  }  
}

@media screen and (max-width: 767px) {
  content h1 {
    font-size: 32px;
    margin-top: 32px;
  }
  .select-wrapper {
    width: 90%;
  }
  button {
    font-size: 16px;
  }
}
